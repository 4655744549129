<template>
  <v-container>
    <div class="d-flex justify-center align-center mb-2 full-width mt-10 flex-column">
      <div style="width:80px">
        <v-img src="@/assets/vajper-avatar.png"></v-img>
      </div>
      <p v-if="isInvalidCredentials == null" class="text-center">Laddar Vajper</p>
      <div v-if="isInvalidCredentials == null" style="width:80px">
        <v-progress-linear
          v-if="isInvalidCredentials == null"
          indeterminate
          color="grey darken-2"
        ></v-progress-linear>
      </div>
      <p v-else-if="isIncompleteUrl" class="text-center">Ofullständig länk</p>
      <p v-else-if="isInvalidCredentials" class="text-center">Åtkomst till resursen nekades</p>
    </div>
    <!-- <pre>
      viewData: {{ viewData }}
      isIncompleteUrl: {{ isIncompleteUrl }}
      isInvalidCredentials: {{ isInvalidCredentials }}
    </pre> -->
  </v-container>
</template>

<script>
import { reactive, ref, onMounted } from '@vue/composition-api'
import store from '../store/store'
// import { useRoute } from 'vue2-helpers/vue-router'
// import { useRoute } from "vue-router"
import useCore from '../helpers/core'

export default {
  name: 'KobraPortal',
  setup(props, context) {
    const { callApi, hasProperty } = useCore();
    const router = require('@/router/router').default;

    // const route = useRoute();
    const isIncompleteUrl = ref(null);
    const isInvalidCredentials = ref(null);

    const viewData = reactive({
      winId: null,
      priId: null,
      usrId: null,
      hash: null,
      username: null,
      uuid: null,
      layoutMode: null,
      targetPath: null,
    });

    const queryParams = [
      {
        name: 'winId',
        required: true,
      },
      {
        name: 'priId',
        required: true,
      },
      {
        name: 'usrId',
        required: true,
      },
      {
        name: 'username',
        required: true,
      },
      {
        name: 'uuid',
        required: true,
      },
      {
        name: 'targetPath',
        required: true,
      },
      {
        name: 'hash',
        required: true,
      },
      {
        name: 'layoutMode',
        required: false,
      },
      {
        name: 'isLocal',
        requred: true,
      }
    ];

    const doOnMounted = async () => {
      let hasQueryParam;
      queryParams.forEach(queryParam => {
        hasQueryParam = hasProperty(context.root._route.query, queryParam.name);
        if (hasQueryParam) {
          let value = context.root._route.query[queryParam.name];
          viewData[queryParam.name] = decodeURIComponent(value);
          switch (queryParam.name) {
            case 'layoutMode':
              // store layoutMode in store
              store.dispatch('setLayoutMode', value);
              break;
          }
        } else if (queryParam.required) {
          isIncompleteUrl.value = true;
        }
      });
      if (isIncompleteUrl.value) {
        // do not proceed
        return;
      }

      const data = {
        username: viewData.username,
        uuid: viewData.uuid,
        wantedData: {
          usrId: viewData.usrId,
          winId: viewData.winId,
          priId: viewData.priId,
          hash: viewData.hash,
        }
      }

      // fetch loggedInData for accessToken, winId and priId
      try {
        const apiResponse = await callApi({
          method: 'post',
          path: '/cms/public/kobra-portal-request',
          data,
        });

        if (!apiResponse.loggedInData) {
          isInvalidCredentials.value = true;
          // do not proceed
          return;
        }
        // store logged in data
        const dataToDispatch = {
          loggedInData: apiResponse.loggedInData
        }
        if (apiResponse.token) {
          // also store token
          dataToDispatch.accessToken = apiResponse.token;
        }
        store.dispatch('onAuth', dataToDispatch)
          .then(() => {
            // now we can go to targetPath
            router.replace('/' + viewData.targetPath);
          });
      }
      catch(err) {
        isInvalidCredentials.value = true;
      }
    }

    onMounted(doOnMounted);

    return {
      viewData,
      isInvalidCredentials,
      isIncompleteUrl,
    }
  }
}
</script>

<style>
.spin-me {
  animation: spin-anim 1500ms infinite linear;
}
@keyframes spin-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>